.login {
  position: relative;
}

.login-card {
  display: grid !important;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.15),
    0 1px 3px 3px rgba(0, 0, 0, 0.15) !important;
  background: #fff !important;
  border-radius: calc(0.15rem - 1px) !important;
  position: absolute !important;
  /* top: 50%;   */
  left: 50%;
  transform: translate(-50%, 50%);
  width: 900px !important;
  height: auto !important;
  grid-template-columns: 1fr 1fr !important;
}

input:focus {
  border-color: #ee3e80;
  outline: none !important;
}

.image-side {
  background: #ee3e80;
  width: 60%;
  padding: 60px;
}

.logo {
  align-items: center;
}

.login-password-text {
  position: absolute;
  cursor: text;
  font-size: 80%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  background: #fff;
  font-weight: 300;
  font-size: 0.65rem;
}

.form-side {
  padding: 60px;
  /* width: 60%; */
  position: relative;
  margin-left: -100px;
}

.form-field {
  position: relative;
  margin-bottom: 1rem;
}

.form-input-field {
  border-radius: 0.1rem;
  width: 100%;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 0.8rem;
  border: 1px solid #d7d7d7;
  background: #fff;
  color: #3a3a3a;
  height: auto;
  padding: 0.5rem 0.75rem;
  line-height: 1;
}

.login-button-position {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.login-button {
  background-color: #ee3e80;
  border: none;
  border-radius: 50px;
  color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.15),
    0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  height: 40px;
  width: 120px;
  cursor: pointer;
}
